@import 'styles/includes.scss';

.Root {
    position: relative;
    width: 100%;
    height: 100%;

    overflow: hidden;

    @include media(ml) {
        transition: all 0.2s ease-out;

        &:hover {
            box-shadow: 24px 24px 0 0 $color-light-pink;
        }
    }
}

.Figure {
    width: 100%;
    height: 100%;
    position: relative;

    @include media(ml) {
        &:hover {
            .Overlay {
                background-color: rgba(0, 0, 0, 0);
            }
        }
    }
}

.Image {
    display: block;
    width: 100%;
    max-height: 500px;
    object-fit: cover;

    @include media(ml) {
        max-height: none;
        height: 100%;
    }
}

.Overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    transition: all 0.2s ease-out;

    @include media(ml) {
        background-color: rgba(0, 0, 0, 0.25);
    }
}

.Caption {
    position: relative;
    // @include h3-headline;
    font-weight: $black;
    @include textStyle(17, 24);

    @media (min-width: $bp-l) {
        @include textStyle(22, 32);
    }

    color: $color-black;
    background-color: $color-white;

    padding: 20px;
    width: auto;
    display: inline-block;
    margin: -50px 0 0 0;

    &:before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    @include media(ml) {
        transition: all 0.2s ease-out;

        position: absolute;
        top: 24px;
        left: 24px;

        margin-top: 0px;
        padding: 24px 28px;

        &:before {
            display: none;
        }
    }
}

.Link {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;

    &:focus {
        .Caption {
            border: 1px dotted rgba(palette(purple),0.7);
        }
    }
}

.Brand {
    @include body-copy-small;
    font-weight: normal;

    @include media(ml) {
        @include body-copy;
    }
}

.NativePlayerWrapper {
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;

    video {
        height: 53.6rem;
        position: relative;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
    }
}

.ExternalPlayerWrapper {
    position: absolute;
    top: 0;

    width: 100%;
    height: 100%;

    overflow: hidden;
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform: scale(3);
    }
}
