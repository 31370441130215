$basepath: '/';

$bp-xs: 340px;
$bp-s: 375px;
$bp-sm: 480px;
$bp-sl: 580px;
$bp-ct: 590px;
$bp-m: 768px;
$bp-ml: 960px;
$bp-l: 1280px;
$bp-xl: 1440px;
$bp-xxl: 1680px;
$bp-xxxl: 1920px;
$bp-xxxxl: 2160px;

$breakpoints: (
    xs: $bp-xs,
    s: $bp-s,
    sm: $bp-sm,
    sl: $bp-sl,
    ct: $bp-ct,
    m: $bp-m,
    ml: $bp-ml,
    l: $bp-l,
    xl: $bp-xl,
    xxl: $bp-xxl,
    xxxl: $bp-xxxl,
    xxxxl: $bp-xxxxl,
);

$max-l: 1280px;
$max-xl: 1440px;
$max-xxl: 1680px;
$max-hero-text: 832px;
$max-content: 832px;
$max-heading: 720px;

$wrap-gutter: 15px;
$wrap-gutter-l: 72px;
$wrap-gutter-xl: 88px;
$wrap-gutter-xxl: 120px;

$palettes: (
    purple: (
        d: #3f0091,
        20: #baa2d9,
        80: #250154,
    ),
    green: (
        d: #37f48d,
        20: #f2f9f4,
    ),
    black: (
        d: #0f0f0f,
    ),
    white: (
        d: #ffffff,
    ),
    red: (
        d: #ff4f2d,
    ),
    pink: (
        d: #ffe7de,
        20: #fffaf8,
    ),
);

$color-dark-violet: palette(purple, 80);
$color-indigo: palette(purple);
$color-lavender: palette(purple, 20);
$color-weird-green: palette(green);
$color-ice: palette(green, 20);
$color-orangey-red: palette(red);
$color-light-pink: palette(pink);
$color-very-light-pink: palette(pink, 20);
$color-black: palette(black);
$color-white: palette(white);

$base-font: 'sofia_pro', sans-serif;
$black: 900;
$bold: 700;
$medium: 600;
$normal: 400;
$light: 300;

$z-behind: -1;

$z-front: 70;
$z-menu-content: 80;
$z-mobilemenu-button: 101;
$z-logo: 100;
$z-modal: 101;
