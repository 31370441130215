/*
 * For setting font-size and line-height on elements defined by variabels, including media queries
 * e.g. @include h1-headline;
 */

@use "sass:math";

@mixin fontSize($fontSize) {
    font-size: #{$fontSize * 0.1}rem;
}

@mixin lineHeight($fontSize, $lineHeight: $fontSize + 4) {
    line-height: math.div($lineHeight, $fontSize);
}

@mixin textStyle($fontSize, $lineHeight: $fontSize + 4) {
    @include fontSize($fontSize);
    @include lineHeight($fontSize, $lineHeight);
}

@mixin start-headline {
    font-weight: $black;

    @include textStyle(50, 51);

    @media (min-width: $bp-l) {
        @include textStyle(92, 96);
    }
}

@mixin display-headline {
    font-weight: $black;
    @include textStyle(30, 32);

    @media (min-width: $bp-sl) {
        @include textStyle(42, 44);
    }

    @media (min-width: $bp-m) {
        @include textStyle(52, 56);
    }

    @media (min-width: $bp-ml) {
        @include textStyle(68, 70);
    }

    @media (min-width: $bp-l) {
        @include textStyle(112, 120);
    }
}

@mixin h1-headline {
    font-weight: $black;
    @include textStyle(32, 40);

    @media (min-width: $bp-m) {
        @include textStyle(42, 48);
    }

    @media (min-width: $bp-l) {
        @include textStyle(56, 56);
    }

    @media (min-width: $bp-xl) {
        letter-spacing: -0.8px;
        @include textStyle(72, 80);
    }
}

@mixin h2-headline {
    font-weight: $black;
    @include textStyle(22, 32);

    @media (min-width: $bp-m) {
        @include textStyle(26, 32);
    }

    @media (min-width: $bp-l) {
        @include textStyle(34, 40);
    }

    @media (min-width: $bp-xl) {
        letter-spacing: -0.4px;
        @include textStyle(52, 64);
    }
}

@mixin h3-headline {
    font-weight: $black;
    @include textStyle(20, 24);

    @media (min-width: $bp-l) {
        @include textStyle(24, 32);
    }

    @media (min-width: $bp-xl) {
        @include textStyle(32, 40);
    }
}

@mixin h4-headline {
    font-weight: $black;
    @include textStyle(18, 24);

    @media (min-width: $bp-xl) {
        @include textStyle(20, 24);
    }
}

@mixin h5-headline {
    font-weight: $black;
    @include textStyle(16, 24);
}

@mixin navigation-titles {
    font-weight: $medium;
    letter-spacing: 0.4px;
    @include textStyle(16, 16);
}

@mixin navigation-sub-titles {
    letter-spacing: 0.3px;
    @include textStyle(12, 12);
}

@mixin small-labels {
    font-weight: $medium;
    letter-spacing: 1px;
    @include textStyle(12, 12);
}

@mixin preamble {
    font-weight: $normal;
    letter-spacing: 0.2px;
    @include textStyle(24, 32);
}

@mixin quote {
    font-weight: $medium;
    letter-spacing: 0.4px;
    @include textStyle(28, 36);
}

@mixin body-copy {
    font-family: $base-font;
    letter-spacing: 0.2px;
    @include textStyle(18, 32);
}

@mixin standard-link {
    text-decoration: underline;
    letter-spacing: 0.2px;
    @include textStyle(18, 28);
}

@mixin button-title {
    letter-spacing: 0.4px;
    @include textStyle(16, 16);
}

@mixin body-copy-small {
    font-family: $base-font;
    letter-spacing: 0.4px;
    @include textStyle(14, 24);
}

@mixin standard-link-small {
    text-decoration: underline;
    letter-spacing: 0.4px;
    @include textStyle(14, 24);
}

@mixin button-title-small {
    letter-spacing: 0.2px;
    @include textStyle(13, 16);
}

@mixin bio-small {
    font-family: $base-font;
    letter-spacing: 0.4px;
    @include textStyle(12, 18);
}

@mixin box-shadow-soft {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.09);
}
