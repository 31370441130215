@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.75;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeInFromTop {
    0% {
        opacity: 0;
        top: -100px;
    }
    50% {
        opacity: 0.75;
        top: -50px;
    }
    100% {
        opacity: 1;
        top: 0;
    }
}

@keyframes fadeInFromBottom {
    0% {
        opacity: 0;
        top: 100px;
    }
    50% {
        opacity: 0.75;
        top: 50px;
    }
    100% {
        opacity: 1;
        top: 0;
    }
}
