#CookieConsent .cookie-popup {
    .content {
        padding: 20px 12px;
        transition: none !important;

        @include media(ct) {
            padding: 48px 48px 0 !important;
        }
    }

    & > div {
        width: 800px !important;
    }

    .cookie-button,
    #cc-b-acceptall,
    #cc-b-custom {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 48px;
        text-decoration: none;
        font-family: $base-font;

        border-radius: 4px;
        background-color: $color-indigo;
        border: 1px dotted transparent;

        @include navigation-titles;
        color: $color-white;

        @include media(ct) {
            display: inline-flex;
            height: 56px;
            padding: 0 24px;
        }

        &:hover {
            text-decoration: underline;
        }

        &:focus {
            border: 1px dotted #fff;
            text-decoration: underline;
        }
    }

    #cc-b-acceptall {
        background-color: #3f0091;
        color: #fff;
    }

    #cc-b-custom {
        margin-bottom: 1rem !important;
        background-color: #fff;
        color: #0f0f0f;
        border: 1px solid #0f0f0f;
        -webkit-text-fill-color: rgba(0, 0, 0, 0.9);

        @include media(ct) {
            margin-bottom: 0 !important;
        }

        &:focus {
            border: 1px dotted $color-indigo;
        }
    }

    div {
        border-radius: 0 !important;
    }

    #cc-label {
        margin-bottom: 1rem;
        font-size: 2.2rem;
        font-weight: $black;
        letter-spacing: -0.0092rem;
        line-height: 3.2rem;

        @include media(ct) {
            font-size: 3.2rem;
            letter-spacing: 0.02rem;
            line-height: 4rem;
            text-align: center;
        }
    }

    #cc-description {
        margin-bottom: 3rem;
    }

    #cc-description p {
        margin: 0;
        padding: 0 !important;
        font-size: 1.4rem;
        line-height: 2.4rem;
        font-weight: 400;

        @include media(ct) {
            font-size: 1.8rem;
            line-height: 3.2rem;
            text-align: center;
        }
    }

    .actions {
        max-width: unset !important;
        margin-top: 0 !important;
        gap: 1rem;

        @include media(ct) {
            margin-bottom: -2.5rem;
            padding-top: 6.6rem;
        }

        > div {
            @include media(ct) {
                width: 50% !important;
            }

            button {
                transition: none !important;

                &:focus-visible {
                    box-shadow: none !important;
                }
            }

            label {
                gap: 1rem;

                @include media(ct) {
                    justify-content: flex-end !important;
                }
            }

            &:first-child {
                border-right: 0 !important;
                padding-right: 0 !important;
            }

            &:last-child {
                border-left: 0 !important;
                padding: 0 !important;

                label {
                    @include media(ct) {
                        &:nth-child(3) {
                            margin-bottom: -36px;
                            margin-left: 40px;
                            position: relative;
                            left: calc(-100% - 2rem);
                            top: calc(-100% - 4rem);
                        }
                        &:nth-child(4) {
                            margin-right: 4rem;
                            position: relative;
                            top: calc(-100% - 4rem);
                        }
                    }
                }
            }
        }
    }

    #cc-c-marketing,
    #cc-c-statistics {
        width: 52px;
        height: 26px;
        background-color: #d6d2d0;
        transition: none;
        border: 1px solid transparent;

        + span {
            font-size: 1.4rem;
            font-weight: 500;
            line-height: 1.33;
            letter-spacing: 0.8px;

            @include media(ct) {
                font-size: 1.8rem;
            }
        }

        &::before {
            width: 18px;
            height: 18px;
            top: 3px;
        }

        &:checked {
            background-color: $color-indigo;

            &:before {
                left: calc(100% - 2.4rem);
            }
        }

        &:focus-visible {
            box-shadow: none;
        }

        &:focus {
            border: 1px dotted $color-indigo;
        }
    }

    #cc-c-statistics {
        + span {
            @include media(ct) {
                margin-left: auto;
            }
        }
    }

    .details {
        min-height: 4em !important;
        background-color: #ebe4f4 !important;
    }

    #cc-b-details {
        font-family: $base-font;
        font-size: 1.4rem;
        line-height: 1.33;
        letter-spacing: 0.8px;
        color: $color-black;
        background-color: $color-white;
        display: flex;
        align-items: center;
        justify-content: center;

        @include media(ct) {
            font-size: 1.8em;
        }

        &::after {
            content: "";
            width: 1.2rem;
            height: 0.6rem;
            background-image: url(#{$basepath}img/svg/icon-arrow-down.svg);
            background-repeat: no-repeat;
            background-size: contain;
            position: relative;
            left: 2rem;
            top: 0.1rem;
        }

        &[aria-expanded="true"] {
            &::after {
                transform: rotate(180deg);
            }
        }

        &:hover {
            text-decoration: underline;
        }
    }

    .processors {
        > li button {
            font-family: $base-font;
        }

        > li button span {
            &:first-child {
                &::after {
                    background-color: $color-indigo !important;
                }
            }
        }
    }
}

.cc-consentwrapper {
    width: 100%;
    height: 100%;

    &::before {
        background: #250154;
    }
}

.cc-consentwrapper .cc-blocker button {
    position: relative;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px !important;
    text-decoration: none;
    font-family: $base-font !important;

    border-radius: 4px !important;
    background-color: $color-indigo !important;
    border: 1px dotted transparent !important;

    @include navigation-titles;
    color: $color-white;

    @include media(ct) {
        display: inline-flex;
        height: 56px;
        padding: 0 24px;
    }

    &:hover {
        text-decoration: underline;
    }

    &:focus {
        border: 1px dotted #fff;
        text-decoration: underline;
    }
}
